import Cookie from 'cookie-universal'
const cookies = Cookie()
const monthStr = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dev.']

export default function (str: string) {
  const locale = cookies.get('i18n_redirected') || 'en_US'
  if (str) {
    if (locale === 'en_US') {
      const date = new Date(str.replace(/-/g, '/'))
      let d: string | number = date.getDay()
      d = d > 9 ? d : '0' + d
      return monthStr[date.getMonth()] + ' ' + d + ',' + date.getFullYear()
    } else {
      return str.split(' ')[0]
    }
  } else {
    return ''
  }
}
