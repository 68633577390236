import { getModule } from 'vuex-module-decorators'
import StoreModule from '@/store/store'
import { useStore } from '@nuxtjs/composition-api'
export default function () {
  return getModule(StoreModule, useStore()).isMobile
}
// vue2 写法拿数据，需要传this进来
export function isMobile(that: any) {
  return that.$store.state.store.isMobile as boolean
}
