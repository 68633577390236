import { render, staticRenderFns } from "./imgWarp.vue?vue&type=template&id=008fc46f&scoped=true&"
import script from "./imgWarp.vue?vue&type=script&lang=ts&"
export * from "./imgWarp.vue?vue&type=script&lang=ts&"
import style0 from "./imgWarp.vue?vue&type=style&index=0&id=008fc46f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "008fc46f",
  null
  
)

export default component.exports