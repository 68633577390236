import { AxiosInstance } from 'axios'
// 查询指定id的博客
export function getblog(axios: AxiosInstance, id: string) {
  return axios.get(`/api/console/blog/portal/${id}`)
}
export function getblogTop(axios: AxiosInstance) {
  return axios.get(`/api/console/blog/portal/top`)
}
// 查询已发布的博客列表
export function getblogList(axios: AxiosInstance, option = {}) {
  const params = Object.assign({ pageIndex: 1, pageSize: 6 }, option)
  return axios.get(`/api/console/blog/portal`, { params })
}
// 查询推荐的文章，默认2条
export function getblogRelated(axios: AxiosInstance, id: string) {
  return axios.get(`/api/console/blog/portal/related/${id}`)
}
// 查询指定预览id的博客
export function getblogPreview(axios: AxiosInstance, id: string) {
  return axios.post(`/api/console/blog/portal/preview`, { id })
}
// 查询最近博客，默认5条
export function getblogLast(axios: AxiosInstance) {
  return axios.get(`/api/console/blog/portal/last`)
}

// 查询博客分类
export function getblogCategory(axios: AxiosInstance) {
  return axios.get(`/api/console/blog/portal/category`)
}
// 查询博客标签
export function getblogTag(axios: AxiosInstance) {
  return axios.get(`/api/console/blog/portal/tag`)
}
