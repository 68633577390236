import { render, staticRenderFns } from "./newsItem.vue?vue&type=template&id=f2fa8d80&scoped=true&"
import script from "./newsItem.vue?vue&type=script&lang=ts&"
export * from "./newsItem.vue?vue&type=script&lang=ts&"
import style0 from "./newsItem.vue?vue&type=style&index=0&id=f2fa8d80&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2fa8d80",
  null
  
)

export default component.exports